import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useJobsList() {
  // Use toast
  const toast = useToast()

  const refJobListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'Number', sortable: true },
    {
      key: 'Post',
      sortable: true,
      formatter: title,
    },
    { key: 'Department', sortable: true },
    { key: 'Opening Date', sortable: true },
    { key: 'Closing Date', sortable: true },
    { key: 'Positions', sortable: true },
    { key: 'actions' },
  ]
  const perPage = ref(10)
  const totalJobs = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('opening_date')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const departmentFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    // es-lint-disable-next-line
    const localItemsCount = refJobListTable.value
      ? refJobListTable.value.localItems.length
      : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalJobs.value,
    }
  })

  const refetchData = () => {
    refJobListTable.value.refresh()
  }

  watch(
    [
      currentPage,
      perPage,
      searchQuery,
      roleFilter,
      departmentFilter,
      statusFilter,
    ],
    () => {
      refetchData()
      // eslint-disable-next-line comma-dangle
    },
  )

  const fetchJobs = () => {
    store
      .dispatch('jobs/fetchJobs', {
        query: {
          q: searchQuery.value,
          f: [
            {
              department: departmentFilter.value || null,
            },
            {
              status: statusFilter.value || null,
            },
          ],
        },
        limit: perPage.value,
        skip: perPage.value * (currentPage.value - 1),
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
      })
      .then(response => {
        const { data } = response.data
        totalJobs.value = data.length
      })
      .catch(err => {
        console.error(err)
        toast(
          {
            component: ToastificationContent,
            props: {
              title: 'Error fetching jobs list',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          },
          {
            position: 'bottom-right',
          },
        )
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveJobRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveJobRoleIcon = role => {
    if (role === 'subscriber') return 'JobIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'JobIcon'
  }

  const resolveJobStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'expired') return 'secondary'
    return 'primary'
  }

  return {
    fetchJobs,
    tableColumns,
    perPage,
    currentPage,
    totalJobs,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refJobListTable,

    resolveJobRoleVariant,
    resolveJobRoleIcon,
    resolveJobStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    departmentFilter,
    statusFilter,
  }
}
