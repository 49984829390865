var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-card',{staticClass:"mb-0",attrs:{"no-body":""}},[_c('div',{staticClass:"m-2"},[_c('b-row',[_c('b-col',{staticClass:"d-flex align-items-center justify-content-start mb-1 mb-md-0",attrs:{"cols":"12","md":"6"}},[_c('label',[_vm._v("Show")]),_c('v-select',{staticClass:"per-page-selector d-inline-block mx-50",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"options":_vm.perPageOptions,"clearable":false},model:{value:(_vm.perPage),callback:function ($$v) {_vm.perPage=$$v},expression:"perPage"}}),_c('label',[_vm._v("entries")])],1),_c('b-col',{attrs:{"cols":"12","md":"6"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-end"},[(false)?_c('b-form-input',{staticClass:"d-inline-block mr-1",attrs:{"placeholder":"Search..."},model:{value:(_vm.searchQuery),callback:function ($$v) {_vm.searchQuery=$$v},expression:"searchQuery"}}):_vm._e(),(_vm.$can('create', 'Job'))?_c('b-button',{attrs:{"variant":"primary"},on:{"click":function($event){return _vm.$router.push({ name: 'new-job' })}}},[_c('span',{staticClass:"text-nowrap"},[_vm._v("Add Job")])]):_vm._e()],1)])],1)],1),_c('b-table',{ref:"refJobListTable",staticClass:"position-relative",attrs:{"items":_vm.jobs,"responsive":"","fields":_vm.tableColumns,"primary-key":"id","sort-by":_vm.sortBy,"show-empty":"","empty-text":"No matching records found","sort-desc":_vm.isSortDirDesc},on:{"update:sortBy":function($event){_vm.sortBy=$event},"update:sort-by":function($event){_vm.sortBy=$event},"update:sortDesc":function($event){_vm.isSortDirDesc=$event},"update:sort-desc":function($event){_vm.isSortDirDesc=$event}},scopedSlots:_vm._u([{key:"cell(number)",fn:function(data){return [_c('b-link',{attrs:{"to":{
            name: 'job-details',
            params: { id: data.item.id },
          }}},[_c('div',{staticClass:"text-nowrap"},[_c('feather-icon',{staticClass:"mr-50",class:"text-primary",attrs:{"icon":"JobIcon","size":"18"}}),_c('span',{staticClass:"align-text-top text-capitalize"},[_vm._v(_vm._s(data.item.number))])],1)])]}},{key:"cell(post)",fn:function(data){return [_c('div',{directives:[{name:"b-popover",rawName:"v-b-popover.hover.right",value:(
            ((data.item.positions) + " position" + (data.item.positions === 1 ? '' : 's') + " available")
          ),expression:"\n            `${data.item.positions} position${\n              data.item.positions === 1 ? '' : 's'\n            } available`\n          ",modifiers:{"hover":true,"right":true}}],staticClass:"text-nowrap",attrs:{"title":data.item.post}},[_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":"light-secondary"}},[_vm._v(" "+_vm._s(data.item.positions)+" ")]),_c('span',{staticClass:"align-text-top text-capitalize"},[_vm._v(_vm._s(data.item.post))])],1)]}},{key:"cell(department)",fn:function(data){return [_c('b-badge',{staticClass:"text-capitalize",attrs:{"pill":"","variant":("light-" + (_vm.resolveJobStatusVariant(data.item.status)))}},[_vm._v(" "+_vm._s(_vm.findDepartment(data.item.department))+" ")])]}},{key:"cell(opening_date)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('span',{staticClass:"align-text-top"},[_vm._v(_vm._s(data.item.toString()))])])]}},{key:"cell(closing_date)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('span',{staticClass:"align-text-top"},[_vm._v(_vm._s(data.item.closing_date))])])]}},{key:"cell(positions)",fn:function(data){return [_c('div',{staticClass:"text-nowrap"},[_c('span',{staticClass:"align-text-top"},[_vm._v(_vm._s(data.item.positions)+" position"+_vm._s(data.item.positions === 1 ? '' : 's'))])])]}},{key:"cell(actions)",fn:function(data){return [_c('b-dropdown',{attrs:{"variant":"link","no-caret":"","right":_vm.$store.state.appConfig.isRTL},scopedSlots:_vm._u([{key:"button-content",fn:function(){return [_c('feather-icon',{staticClass:"align-middle text-body",attrs:{"icon":"MoreVerticalIcon","size":"16"}})]},proxy:true}],null,true)},[(_vm.$can('manage', 'Job'))?_c('b-dropdown-item',{attrs:{"to":{
              name: 'job-dashboard',
              params: { id: data.item.id },
            }}},[_c('feather-icon',{attrs:{"icon":"HomeIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Dashboard")])],1):_vm._e(),_c('b-dropdown-item',{attrs:{"to":{
              name: 'job-details',
              params: { id: data.item.id },
            }}},[_c('feather-icon',{attrs:{"icon":"FileTextIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Details")])],1),(_vm.$can('update', 'Job'))?_c('b-dropdown-item',{attrs:{"to":{
              name: 'job-edit',
              params: { id: data.item.id },
            }}},[_c('feather-icon',{attrs:{"icon":"EditIcon"}}),_c('span',{staticClass:"align-middle ml-50"},[_vm._v("Edit")])],1):_vm._e()],1)]}}])}),_c('div',{staticClass:"mx-2 mb-2"},[_c('b-row',[_c('b-col',{staticClass:"\n            d-flex\n            align-items-center\n            justify-content-center justify-content-sm-start\n          ",attrs:{"cols":"12","sm":"6"}},[_c('span',{staticClass:"text-muted"},[_vm._v("Showing "+_vm._s(_vm.dataMeta.from)+" to "+_vm._s(_vm.dataMeta.to)+" of "+_vm._s(_vm.dataMeta.of)+" entries")])]),_c('b-col',{staticClass:"\n            d-flex\n            align-items-center\n            justify-content-center justify-content-sm-end\n          ",attrs:{"cols":"12","sm":"6"}},[_c('b-pagination',{staticClass:"mb-0 mt-1 mt-sm-0",attrs:{"total-rows":_vm.totalJobs,"per-page":_vm.perPage,"first-number":"","last-number":"","prev-class":"prev-item","next-class":"next-item"},scopedSlots:_vm._u([{key:"prev-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronLeftIcon","size":"18"}})]},proxy:true},{key:"next-text",fn:function(){return [_c('feather-icon',{attrs:{"icon":"ChevronRightIcon","size":"18"}})]},proxy:true}]),model:{value:(_vm.currentPage),callback:function ($$v) {_vm.currentPage=$$v},expression:"currentPage"}})],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }