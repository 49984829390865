<template>
  <div>
    <!-- Filters -->
    <!-- <jobs-list-filters
      :role-filter.sync="roleFilter"
      :plan-filter.sync="departmentFilter"
      :status-filter.sync="statusFilter"
      :role-options="roleOptions"
      :plan-options="planOptions"
      :status-options="statusOptions"
    /> -->

    <b-card
      no-body
      class="mb-0"
    >
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>Show</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>entries</label>
          </b-col>

          <!-- Search -->
          <b-col
            cols="12"
            md="6"
          >
            <div class="d-flex align-items-center justify-content-end">
              <!-- TODO: Add job search -->
              <b-form-input
                v-if="false"
                v-model="searchQuery"
                class="d-inline-block mr-1"
                placeholder="Search..."
              />
              <b-button
                v-if="$can('create', 'Job')"
                variant="primary"
                @click="$router.push({ name: 'new-job' })"
              >
                <span class="text-nowrap">Add Job</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refJobListTable"
        class="position-relative"
        :items="jobs"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(number)="data">
          <b-link
            :to="{
              name: 'job-details',
              params: { id: data.item.id },
            }"
          >
            <div class="text-nowrap">
              <feather-icon
                icon="JobIcon"
                size="18"
                class="mr-50"
                :class="`text-primary`"
              />
              <span class="align-text-top text-capitalize">{{
                data.item.number
              }}</span>
            </div>
          </b-link>
        </template>

        <template #cell(post)="data">
          <div
            v-b-popover.hover.right="
              `${data.item.positions} position${
                data.item.positions === 1 ? '' : 's'
              } available`
            "
            :title="data.item.post"
            class="text-nowrap"
          >
            <b-badge
              pill
              :variant="`light-secondary`"
              class="text-capitalize"
            >
              {{ data.item.positions }}
            </b-badge>
            <span class="align-text-top text-capitalize">{{
              data.item.post
            }}</span>
          </div>
        </template>

        <template #cell(department)="data">
          <b-badge
            pill
            :variant="`light-${resolveJobStatusVariant(data.item.status)}`"
            class="text-capitalize"
          >
            {{ findDepartment(data.item.department) }}
          </b-badge>
        </template>

        <template #cell(opening_date)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.toString() }}</span>
          </div>
        </template>

        <template #cell(closing_date)="data">
          <div class="text-nowrap">
            <span class="align-text-top">{{ data.item.closing_date }}</span>
          </div>
        </template>

        <template #cell(positions)="data">
          <div class="text-nowrap">
            <span
              class="align-text-top"
            >{{ data.item.positions }} position{{
              data.item.positions === 1 ? '' : 's'
            }}</span>
          </div>
        </template>

        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            :right="$store.state.appConfig.isRTL"
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-if="$can('manage', 'Job')"
              :to="{
                name: 'job-dashboard',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="HomeIcon" />
              <span class="align-middle ml-50">Dashboard</span>
            </b-dropdown-item>

            <b-dropdown-item
              :to="{
                name: 'job-details',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="FileTextIcon" />
              <span class="align-middle ml-50">Details</span>
            </b-dropdown-item>

            <b-dropdown-item
              v-if="$can('update', 'Job')"
              :to="{
                name: 'job-edit',
                params: { id: data.item.id },
              }"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50">Edit</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-start
            "
          >
            <span
              class="text-muted"
            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
              {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="
              d-flex
              align-items-center
              justify-content-center justify-content-sm-end
            "
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalJobs"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BBadge,
  BDropdown,
  BDropdownItem,
  BPagination,
  VBPopover,
  BLink,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref } from '@vue/composition-api'
import { avatarText, formatDate } from '@core/utils/filter'
import store from '@/store'

// import JobsListFilters from './JobListFilters.vue'
import useJobsList from './useJobsList'

export default {
  components: {
    // JobsListFilters,

    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    BLink,

    vSelect,
  },
  directives: {
    'b-popover': VBPopover,
  },
  computed: {
    jobs() {
      return this.$store.state.jobs.jobs
    },
    departments() {
      return this.$store.state.departments.departments || []
    },
  },
  methods: {
    formatDate,
    findDepartment(id) {
      const result = this.departments.find(e => e.id === id)
      return result ? result.name : 'Unknown'
    },
  },
  setup() {
    const isAddNewJobSidebarActive = ref(false)

    const {
      fetchJobs,
      tableColumns,
      perPage,
      currentPage,
      totalJobs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refJobListTable,
      refetchData,

      // UI
      resolveJobRoleVariant,
      resolveJobRoleIcon,
      resolveJobStatusVariant,

      // Extra Filters
      roleFilter,
      departmentFilter,
      statusFilter,
    } = useJobsList()

    fetchJobs()
    store.dispatch('departments/fetchDepartments')

    return {
      // Sidebar
      isAddNewJobSidebarActive,

      fetchJobs,
      tableColumns,
      perPage,
      currentPage,
      totalJobs,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refJobListTable,
      refetchData,

      // Filter
      avatarText,

      // UI
      resolveJobRoleVariant,
      resolveJobRoleIcon,
      resolveJobStatusVariant,

      // Extra Filters
      roleFilter,
      departmentFilter,
      statusFilter,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
