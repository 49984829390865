<template>
  <div>
    <jobs-list />
  </div>
</template>

<script>
import JobsList from './jobs/JobsList.vue'

export default {
  components: {
    JobsList,
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
